@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700,900&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "Helvetica Neue", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-width: 1000px;
  color: #333;
  line-height: 1.5;
}

@media (max-width: 425px) {
  body {
    min-width: auto;
  }
}

p {
  font-size: 16px;
  line-height: 1.7;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

@font-face {
  font-family: 'icomoon';
  src: url("/common/fonts/icomoon.eot?6whfht");
  src: url("/common/fonts/icomoon.eot?6whfht#iefix") format("embedded-opentype"), url("/common/fonts/icomoon.ttf?6whfht") format("truetype"), url("/common/fonts/icomoon.woff?6whfht") format("woff"), url("/common/fonts/icomoon.svg?6whfht#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
