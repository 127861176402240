@charset "UTF-8";

// #clearfix
@mixin clearfix {
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    content: '.';
  }
}

// web fontを使う場合
@mixin icon {
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;

  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// sp
@mixin sp {
  @media (max-width: 425px) {
    @content;
  }
}

@mixin sp_small {
  @media (min-width: 320px) and (max-width: 374px) {
    @content;
  }
}

// color
$colorBlue: #17ABED;
$colorBlack: #333333;
$colorGray: #F5F5F5;
$colorYellow: #FFDE6F;
$colorLightGreen: #3BCCA5;
$colorLightBlue: #64BFCB;
$colorGreen: #008802;
$colorNavy: #00467E;

// icon
$iconFlag: '\e903';
$iconGlass: '\e904';
$iconLock: '\e905';
$iconMail: '\e906';
$iconMarker: '\e907';
$iconMic: '\e908';
$iconNote: '\e909';
$iconPen: '\e90a';
$iconPerson: '\e90b';
$iconQuestion: '\e90c';
$iconSpeech: '\e90d';
$iconTwitter: '\e902';
$iconFacebook: '\e900';
$iconHome: '\e90e';
$iconExclamation: '\e90f';
$iconDownload: '\e910';

// font weight
$fwRegular: 400;
$fwBold: 700;
$fwBlack: 900;
