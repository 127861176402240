@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700,900&display=swap');


* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "Helvetica Neue", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-width: 1000px;
  color: #333;
  line-height: 1.5;
  @include sp {
    min-width: auto;
  }
}

p {
  font-size: 16px;
  line-height: 1.7;
}
a {
  color: inherit;
  text-decoration: none;
}
img {
  max-width: 100%;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/common/fonts/icomoon.eot?6whfht');
  src:  url('/common/fonts/icomoon.eot?6whfht#iefix') format('embedded-opentype'),
  url('/common/fonts/icomoon.ttf?6whfht') format('truetype'),
  url('/common/fonts/icomoon.woff?6whfht') format('woff'),
  url('/common/fonts/icomoon.svg?6whfht#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
